import { Component, OnInit, Input, ElementRef, ChangeDetectorRef } from "@angular/core";
import { EventPreferencesService, EventPreferences, Item, Group } from "../../../services/event.preferences.service";
import { MultiselectModelI } from "../../s25-multiselect/s25.multiselect.component";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Util } from "../../../util/s25-util";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { Debounce } from "../../../decorators/debounce.decorator";
@TypeManagerDecorator("s25-ng-ev-prefs")
@Component({
    selector: "s25-ng-ev-prefs",
    template: `
        @if (init) {
            <div class="spacer-bottom">
                <!-- <s25-loading-inline [model]="{}"></s25-loading-inline> -->
                <div class="evdo-panel">
                    <div class="panel-header">
                        <div class="ngBold">
                            Event Preferences
                            @if (panelIndex !== 0) {
                                <span>{{ eventName }}</span>
                            }
                        </div>
                        <span class="c-margin-right--half">
                            @if (isExpand) {
                                <span (click)="editToggle()" class="c-textButton">
                                    {{ isEdit ? "View" : "Edit" }}
                                </span>
                            }
                            @if (!isExpand) {
                                <span (click)="expandToggle(true)">
                                    <svg class="c-svgIcon" role="button">
                                        <title>Expand</title>
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-up"
                                        ></use>
                                    </svg>
                                </span>
                            }
                            @if (isExpand) {
                                <span (click)="expandToggle(false)">
                                    <svg class="c-svgIcon" role="button">
                                        <title>Collape</title>
                                        <use
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#caret--caret-down"
                                        ></use>
                                    </svg>
                                </span>
                            }
                        </span>
                    </div>
                    @if (isExpand) {
                        <div class="pref-panel">
                            @if (preference?.panelId) {
                                <div>
                                    {{ featureItem.lenght }}
                                    @if (
                                        (featureItem.length !== 0 &&
                                            partitionItem.length !== 0 &&
                                            locationItem.length !== 0 &&
                                            resourceItem.length !== 0) ||
                                        isEdit
                                    ) {
                                        <span class="adj-line-height">
                                            {{ panelIndex === 0 ? "This event" : eventName }} has the following
                                            preferences
                                        </span>
                                    }
                                    @if (
                                        featureItem.length === 0 &&
                                        partitionItem.length === 0 &&
                                        locationItem.length === 0 &&
                                        resourceItem.length === 0 &&
                                        !isEdit
                                    ) {
                                        <span> (none) </span>
                                    }
                                    @for (i of itemList; track i) {
                                        <div>
                                            @if (this[i.itemName]?.item?.length > 0 || isEdit) {
                                                <div>
                                                    <div class="ngBold">{{ i.displayName }}</div>
                                                    @for (item of this[i.itemName]?.item; track item) {
                                                        <div class="c-margin-left--single">
                                                            {{ item.itemName }}
                                                            @if (item.quantity) {
                                                                <span>[Quantity: {{ item.quantity }}]</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            @if (isEdit) {
                                                <div class="buttons adj-line-height c-margin-bottom--single">
                                                    @if (!this[i.itemName]?.item) {
                                                        <div class="c-margin-bottom--quarter">(none)</div>
                                                    }
                                                    <s25-ng-multiselect-search-criteria
                                                        [modelBean]="this[i.bean]"
                                                        [selectedItems]="this[i.itemName]?.item"
                                                        [type]="[i.type]"
                                                    ></s25-ng-multiselect-search-criteria>
                                                    @if (this[i.itemName]?.item?.length > 0) {
                                                        <button
                                                            class="aw-button aw-button--outline c-margin-left--single"
                                                            (click)="onRemoveAction(i.objectId)"
                                                        >
                                                            Remove
                                                        </button>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    @if (!preference?.panelId) {
                                        <div>None</div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        }
    `,
    styles: [
        `
            :host ::ng-deep .spacer-bottom {
                margin-bottom: 20px;

                .evdo-panel {
                    padding: unset;

                    .panel-header {
                        border-bottom: 1px solid #999;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .ngBold {
                            letter-spacing: 1px;
                            font-size: 1rem;
                            padding: 10px;
                        }

                        .c-textButton {
                            font-size: 1rem;
                        }
                    }

                    .pref-panel {
                        padding: 10px;
                        font-size: 1rem;
                    }
                }
            }
        `,
    ],
})
export class S25EventPreferencesComponent implements OnInit {
    @Input() eventId?: number;
    @Input() type: string = "location" || "resource";
    @Input() panelIndex?: number = 0;
    @Input() eventName?: string;

    isExpand: boolean = false;
    isEdit: boolean = false;
    init: boolean = false;
    preference: EventPreferences;

    modelBean = { showResult: false, showMatching: true };
    featureBean: MultiselectModelI = { buttonText: "Edit", buttonClass: "aw-button--primary" };
    partitionBean: MultiselectModelI = { buttonText: "Edit", buttonClass: "aw-button--primary" };
    locationBean: any = { buttonText: "Edit", buttonClass: "aw-button--primary" };
    resourceBean: any = { buttonText: "Edit", buttonClass: "aw-button--primary" }; //MultiselectModelI; //SearchCriteriaContextI;

    // 410 = Feature, 423 = partition, 407 = location,  607 = resource
    //itemList = [410, 423, 407, 607];
    itemList = [
        {
            objectId: 410,
            objectType: "feature",
            displayName: "Features:",
            itemName: "featureItem",
            type: "locationFeatures",
            action: "editFeature",
            bean: "featureBean",
        },
        {
            objectId: 423,
            objectType: "partition",
            displayName: "Partitions:",
            itemName: "partitionItem",
            type: "locationPartitions",
            action: "editPartition",
            bean: "partitionBean",
        },
        {
            objectId: 407,
            objectType: "location",
            displayName: "Locations:",
            itemName: "locationItem",
            type: "locations",
            action: "editLocation",
            bean: "locationBean",
        },
        {
            objectId: 607,
            objectType: "resource",
            displayName: "Resources:",
            itemName: "resourceItem",
            type: "resourcesQuantity",
            action: "editResource",
            bean: "resourceBean",
        },
    ];
    featureItem: S25ItemI = [];
    partitionItem: S25ItemI = [];
    locationItem: S25ItemI = [];
    resourceItem: S25ItemI = [];
    isSeperateView: boolean = false;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        const data = await EventPreferencesService.getPreferences(this.eventId);
        if (this.eventName.indexOf("Rsrv_") === -1) {
            this.eventName = this.eventName.replace("Rsrv_", "Segment #");
        }

        this.preference = (data?.panel && data?.panel[this.panelIndex]) || [];

        this.featureBean.action = this.editFeature;
        this.partitionBean.action = this.editPartition;
        this.locationBean.action = this.editLocation;
        this.resourceBean.action = this.editResource;

        this.featureItem = await this.itemFilter(410);
        this.partitionItem = await this.itemFilter(423);
        this.locationItem = await this.itemFilter(407);
        this.resourceItem = await this.itemFilter(607);

        // All filtered displays items
        this.locationBean.items = this.locationItem?.item || [];
        this.resourceBean.items = this.resourceItem?.item || [];
        // set to re-selected all filtered
        this.locationBean.domainFilter = {
            chosen: {
                itemName: "All filters",
                itemId: -999,
            },
        };
        this.resourceBean.domainFilter = {
            chosen: {
                itemName: "All filters",
                itemId: -999,
            },
        };

        this.init = true;
        this.cd.detectChanges();
    }

    expandToggle(e: boolean) {
        this.isExpand = e;
        this.isEdit = false;
        this.cd.detectChanges();
    }

    editToggle() {
        this.isEdit = !this.isEdit;
        this.cd.detectChanges();
    }

    async onRemoveAction(typeId: number) {
        let find: any = this.preference.group.filter((g: Group) => {
            return g.typeId === typeId;
        });

        // ANG-4541 updated item with empty [] instead of call delete method
        const ok = await EventPreferencesService.putPreferences(
            this.eventId,
            this.preference.panelId,
            typeId,
            find.queryId,
            [],
        ).catch(this.error);

        if (ok) {
            this.ngOnInit();
            this.cd.detectChanges();
        }

        //    const ok = await EventPreferencesService.deletePreferences(
        //        this.eventId,
        //        this.preference.panelId,
        //        typeId,
        //        find.queryId,
        //    ).catch(this.error);
    }

    editFeature = () => {
        this.editSelectedActions(410, this.featureBean.selectedItems);
    };

    editPartition = () => {
        this.editSelectedActions(423, this.partitionBean.selectedItems);
    };

    editLocation = () => {
        this.editSelectedActions(407, this.locationBean.selectedItems);
    };

    editResource = () => {
        this.resourceBean.selectedItems = this.resourceBean.selectedItems.map((item: S25ItemI) => ({
            itemId: item.itemId,
            itemName: item.itemName,
            checked: true,
            quantityMax: item.quantityMax,
            quantity: item.quantity || 1,
            quantitySrc: item.quantitySrc || 1,
        }));
        this.editSelectedActions(607, this.resourceBean.selectedItems);
    };

    @Debounce(300)
    async editSelectedActions(typeId: number, items: any) {
        let find: any = this.preference.group.filter((g: Group) => {
            return g.typeId === typeId;
        });

        items = items.map((item: any) => ({
            ...item,
            isPrivate: false,
            itemTypeId: typeId,
        }));

        const ok = await EventPreferencesService.putPreferences(
            this.eventId,
            this.preference.panelId,
            typeId,
            find.queryId,
            items,
        ).catch(this.error);
        if (ok) {
            this.ngOnInit();
            this.cd.detectChanges();
        }
    }

    itemFilter(typeId: number) {
        if (this.preference?.group) {
            let find: any = this.preference.group.filter((g: Group) => {
                return g.typeId === typeId;
            });

            if (find.length > 0) {
                return find[0];
            } else {
                return [];
            }
        } else {
            return []; // no data reutrn empty array
        }
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
