import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25EventPreferencesComponent } from "./s25-event-preferences/s25.event.preferences.component";
import { OccSliderComponent } from "./s25-event-occurrences/occ-slider/occ-slider.component";
import { EvOccMenuComponent } from "./s25-event-occurrences/ev-occ-menu/ev-occ-menu.component";
import { S25EventColanderComponent } from "./s25-event-colander/s25-event-colander.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";

@NgModule({
    declarations: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
    providers: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
    imports: [CommonModule, S25MultiselectModule, S25LoadingInlineModule],
    exports: [S25EventPreferencesComponent, OccSliderComponent, EvOccMenuComponent, S25EventColanderComponent],
})
export class S25EventDetailsModule {}
